<template>
	<div class="header-in">
		<el-row>
			<el-col :span="6">
				<router-link to="/" class="logo-wrap">
					<img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" />
					<img v-else src="@/assets/images/logo.png" />
				</router-link>
			</el-col>
			<el-col :span="13">
				<div class="in-sousuo">
					<div class="sousuo-box">
						<el-dropdown @command="handleCommand" trigger="click">
							<span class="el-dropdown-link">
								{{ searchTypeText }}
								<i class="el-icon-arrow-down"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="goods">产品</el-dropdown-item>
								<el-dropdown-item command="shop">店铺</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" />
						<el-button type="primary" size="small" @click="search">搜索</el-button>
					</div>
					<div class="hot-search-words" v-if="hotSearchWords.length">
						<span>热门搜索：</span>
						<ul>
							<li v-for="(item, index) in hotSearchWords" :key="index" @click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })">
								{{ item }}
							</li>
						</ul>
					</div>
				</div>
				<div class="search_texts">
					<span v-for="(item,index) in goodsCategoryTree" v-show="index<6" :key="index">{{item.category_name}}</span>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="cart-wrap">
					<el-image  v-if="siteInfo.web_qrcode" :src="$img(siteInfo.web_qrcode)" @error="imageError" fit="contain" class="img">
						<!-- <div slot="error" class="image-slot">
							<img :src="require('@/assets/images/wxewm.png')" />
						</div> -->
					</el-image>
					<el-image class="img" v-else :src="require('@/assets/images/wxewm.png')"></el-image>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { tree } from '@/api/goods/goodscategory';
import { mapGetters } from 'vuex';
import { apiHotSearchWords, apiDefaultSearchWords } from '@/api/pc';
import { cartList as apiCartList } from '@/api/goods/cart';
export default {
	props: {},
	data() {
		return {
			searchType: 'goods',
			searchTypeText: '产品',
			keyword: '',
			hotSearchWords: [],
			defaultSearchWords: '',
			cartList: [],
			cartTotalPrice: 0,
			goodsCategoryTree:[],
		};
	},
	components: {},
	computed: {
		...mapGetters(['cartCount', 'siteInfo', 'defaultGoodsImage', 'member'])
	},
	created() {
		this.keyword = this.$route.query.keyword || '';
		if (this.$route.name == 'street') this.searchType = 'shop';
		else this.searchType = 'goods';
		this.$store.dispatch('site/siteInfo');
		this.getHotSearchWords();
		this.getDefaultSearchWords();
		this.getCartList();
		this.getTree();
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == 'goods' ? '产品' : '店铺';
		},
		$route(curr) {
			if (this.keyword !== curr.query.keyword) {
				this.keyword = curr.query.keyword;
			}
			if (this.$route.name == 'street') this.searchType = 'shop';
			else this.searchType = 'goods';
		},
		cartCount() {
			if (this.member) this.getCartList();
		},
		member() {
			if (!this.member) {
				this.$store.commit('cart/SET_CART_COUNT', 0);
				this.cartList = [];
				this.cartTotalPrice = 0;
			}
		}
	},
	methods: {
		getTree() {
			tree({
				level: 3,
				template: 2
			})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.goodsCategoryTree = res.data || [];
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		handleCommand(command) {
			this.searchType = command;
		},
		search() {
			if (this.searchType == 'goods') this.$router.push({ path: '/list', query: { keyword: this.keyword } });
			else this.$router.push({ path: '/street', query: { keyword: this.keyword } });
		},
		getHotSearchWords() {
			apiHotSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.hotSearchWords = res.data.words.split(',');
				}
			});
		},
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words;
				}
			});
		},
		// 获取购物车数据
		getCartList() {
			apiCartList({})
				.then(res => {
					if (res.code >= 0 && res.data.length) {
						this.cartTotalPrice = 0
						this.cartList = res.data
						this.cartList.forEach(item => {
							this.cartTotalPrice += item.discount_price * item.num
						});
						this.cartTotalPrice = this.cartTotalPrice.toFixed(2)
					}
				})
				.catch(res => {});
		},
		imageError(index) {
			this.cartList[index].sku_image = this.defaultGoodsImage;
		},
		// 删除单个购物车商品
		deleteCart(index) {
			this.$store
				.dispatch('cart/delete_cart', {
					cart_id: this.cartList[index].cart_id.toString()
				})
				.then(res => {
					if (res.code >= 0) {
						this.cartList.splice(index, 1);
						this.$forceUpdate();
					}
				})
				.catch(err => {});
		}
	}
};
</script>

<style scoped lang="scss">
.header-in {
	width: $width;
	height: 80px;
	margin: 0 auto 14px;
	padding-top: 12px;
	.el-row{
		display: flex;
		align-items: center;
	}
	.logo-wrap {
		width: 160px;
		height: 62px;
		line-height: 62px;
		display: block;
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
	.search_texts{
		display: flex;
		align-items: center;
		margin-top: 10px;
		span{
			color: #999;
			font-size: 12px;
			line-height: 16px;
			margin-right: 16px;
		}
	}
	.in-sousuo {
		width: 632px;
		.sousuo-box {
			width: 100%;
			height: 40px;
			border: 2px solid $base-color;
			box-sizing: border-box;
			// border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.el-dropdown {
				padding-left:16px;
				cursor: pointer;
				color: #222;
				padding-right: 9px;
				border-right: 1px solid #DBDBDB;
				margin-right: 9px;
				line-height: 26px;
				.el-icon-arrow-down{
					color: #222;
					font-weight: 600;
				}
				
			}
			input {
				width: 380px;
				height: 36px;
				background: none;
				outline: none;
				border: none;
				margin: 0;
				padding: 0 20px 0 0;
				height: 100%;
				flex: 1;
				color: #999;
			}
			button {
				height: 100%;
				border-radius: 0;
				// float: right;
				padding: 9px 25px;
			}
		}
		.hot-search-words {
			width: 100%;
			height: 20px;
			margin-top: 5px;
			font-size: 12px;
			span {
				float: left;
			}
			ul {
				overflow: hidden;
				margin: 0;
				height: 21px;
				padding: 0;
				color: $ns-text-color-black;
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					margin-right: 10px;
					&:hover {
						color: $base-color;
					}
				}
			}
		}
	}
	.cart-wrap {
		position: relative;
		float: right;
		padding-right: 60px;
		.img{
			width: 80px;
			height: 80px;
			display: block;
		}
	}
}
</style>
