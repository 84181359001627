<template>
    <div>
        <div class="header-top">
            <div class="top-content">
                <div class="top-left">
					<div class="tit">欢迎来到新商汇-智汇商品交易平台</div>
					<div class="action">
						<router-link v-if="!logined" to="/login" class="ns-text-color">登录</router-link>
						<div v-if="logined" class="member-info">
						    <router-link to="/member/workbench">{{ member.nickname || member.username }}</router-link>
						    <span @click="logout">退出</span>
						</div>
						<div class="line" v-if="!logined"></div>
						<router-link v-if="!logined" class="ns-text-color" to="/register">注册</router-link>
					</div>
                </div>
                <div class="top-right">
                    <router-link to="/cms/master">站内信</router-link>
					<router-link to="/cart">购物车</router-link>
                    <router-link to="/member/workbench">会员中心</router-link>
                    <!-- <router-link to="/member/order_list">我的消息</router-link> -->
                    <router-link to="/cms/help">帮助中心</router-link>
					<router-link to="/cms/notice">公告</router-link>
					<!-- <router-link to="/member/footprint">我的足迹</router-link> -->
					<!-- <router-link to="/member/collection">我的关注</router-link> -->
                    <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            手机端<i class="el-icon-caret-bottom"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
								<div class="mobile-qrcode"><img :src="$img(qrcode)" /></div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { getToken } from "@/utils/auth"

    export default {
        props: {},
        data() {
            return {}
        },
        created() {
            this.$store.dispatch("site/qrCodes")
            this.$store.dispatch("member/member_detail")
            this.$store.dispatch("site/defaultFiles")
            this.$store.dispatch("site/addons")
        },
        mounted() {},
        watch: {},
        methods: {
            logout() {
                this.$store.dispatch("member/logout")
            }
        },
        components: {},
        computed: {
            ...mapGetters(["wapQrcode", "member", "addonIsExit", "city"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            },
            logined: function() {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-top {
        width: 100%;
        height: 40px;
        font-size: 12px;
        background-color: #242424;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }
        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;
            .top-left {
				display: flex;
				align-items: center;
                height: 100%;
                float: left;
                height: 100%;
                line-height: 16px;
				.tit{
					color: #fff;
					font-size: 12px;
					margin-right: 13px;
				}
                .action{
					font-size: 12px;
					display: flex;
					align-items: center;
					.line{
						background-color: $base-color;
						width: 1px;
						height: 13px;
						margin: 0 8px;
					}
					div{
						&.member-info {
							color: #fff;
							a{
								margin-right: 10px;
								color: $base-color;
							}
							span{
								cursor: pointer;
							}
						}
					}
				}
            }
            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                color: #fff;
                a {
                    float: left;
                    line-height: 40px;
					color: #fff;
					padding: 0 21px;
                    &:hover {
                        color: $base-color;
                    }
                }
                div {
                    float: left;
                    height: 100%;
                    margin-right: 2px;
                    cursor: pointer;
                    line-height: 40px;
					padding-left: 15px;
					color: #fff;
                    .el-icon-caret-bottom{
						margin-left: 6px;
					}
                    span:hover {
                        color: $base-color;
                    }
                    // &.el-dropdown:hover {
                    //     background-color: #fff;
                    // }
                }
            }
        }
    }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.router-link-active{
		color:$base-color;
	}
</style>
