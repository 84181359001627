<template>
    <div class="footer">
        <el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0">
            <el-tab-pane label="友情链接" name="first">
                <div class="items">
                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)"/></span>
                    </div>
                    <div class="clear"></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- <div class="friendly-link" v-if="linkList.length > 0">
			<div class="link-title">友情链接</div>
			<div>
				<div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
					<span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)" /></span>
				</div>

				<div class="clear"></div>
			</div>
		</div> -->
        <div class="footer-top" v-if="shopServiceList.length > 0">
            <ul class="service">
                <li v-for="(item, index) in shopServiceList" :key="index">
					<div class="left">
						<img :src="$util.img(item.icon)" alt="">
					</div>
					<div class="right">
						<div class="name">{{item.name}}</div>
						<div class="desc">{{item.desc}}</div>
					</div>
                </li>
            </ul>
        </div>

        <!-- <div class="footer-center">
            
        </div> -->

        <div class="footer-bot" >
			<!-- <div class="left" v-if="siteInfo.web_phone || siteInfo.web_email">
				<div>
					<p class="left-phone" v-if="siteInfo.web_phone">{{ siteInfo.web_phone }}</p>
					<p class="left-email">{{ siteInfo.web_email }}</p>
				</div>
			</div> -->
			<div class="content">
				<div class="center" v-if="helpList.length">
					<div class="help" v-for="(item, index) in helpList" :key="index">
						<p @click="clickToHelp(item.class_id)" class="class_name">{{ item.class_name }}</p>
						<ul class="help-ul">
							<li class="help-li" v-for="(helpItem, helpIndex) in item.child_list" :key="helpIndex" @click="clickToHelpDetail(helpItem.id)">{{ helpItem.title }}</li>
						</ul>
					</div>
					
					<div class="right">
						<div class="top">
							<div class="tit">服务热线</div>
							<div class="phone" v-if="siteInfo.web_phone">{{siteInfo.web_phone }}</div>
						</div>
						<div class="scan">
							<el-image  v-if="siteInfo.web_qrcode" :src="$img(siteInfo.web_qrcode)" @error="imageError" fit="contain">
							</el-image>
							<el-image v-else :src="require('@/assets/images/wxewm.png')"></el-image>
							<div class="scan_text">微信客服</div>
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		<div class="footer-fot">
			<copy-right class="copy_right" />
		</div>
    </div>
</template>

<script>
    import { copyRight, shopServiceLists, friendlyLink } from "@/api/website"
    import CopyRight from "./CopyRight"
    import { mapGetters } from "vuex"
    import { helpList } from "@/api/cms/help"

    export default {
        props: {},
        data() {
            return {
                shopServiceList: [],
                linkList: [],
                helpList: [],
                ishide: false,
                activeName: "first",
            }
        },
        computed: {
            ...mapGetters(["siteInfo"])
        },
        created() {
            this.getShopServiceLists()
            this.link()
            this.getHelpList()
        },
        mounted() {},
        watch: {},
        methods: {
            getShopServiceLists() {
                shopServiceLists({}).then(res => {
                    if (res.code == 0 && res.data) {
                        this.shopServiceList = res.data
                    }
                })
            },
            link() {
                friendlyLink({})
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.linkList = res.data.list
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            linkUrl(url, target) {
                if (!url) return
                if (url.indexOf("http") == -1) {
                    if (target) this.$router.pushToTab({ path: url })
                    else this.$router.push({ path: url })
                } else {
                    if (target) window.open(url)
                    else window.location.href = url
                }
            },
            /**
             * 获取帮助列表
             */
            getHelpList() {
                helpList()
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            // var arr = [];
                            // arr = res.data.slice(0, 5)

                            // for (let i=0; i<arr.length; i++) {
                            //     arr[i].child_list = arr[i].child_list.slice(0, 4);
                            // }

                            this.helpList = res.data
                        }
                    })
                    .catch(err => {})
            },
            /**
             * 图片加载失败
             */
            imageError() {
                this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
            },
            /**
             * 跳转到帮助列表
             */
            clickToHelp(id) {
                this.$router.push("/cms/help/listother-" + id)
            },
            /**
             * 跳转到帮助详情
             */
            clickToHelpDetail(id) {
                this.$router.push("/cms/help-" + id)
            }
        },
        components: { CopyRight }
    }
</script>

<style scoped>
	.right /deep/ .el-image__inner {
		vertical-align: baseline;
		max-width: 100%;
		max-height: 100%;
	 }
</style>
<style scoped lang="scss">
    .footer {
        .footer-top {
            background-color: #0DAD94;
            .service {
                width: $width;
                margin: 0 auto;
                padding: 34px 0 28px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                li {
					max-width: 220px;
					display: flex;
					align-items: flex-start;
					.left{
						width: 48px;
						height: 48px;
						min-width: 48px;
						margin-right: 12px;
						img{
							width: 100%;
							height: 100%;
							display: block;
						}
					}
					.right{
						.name{
							color: #fff;
							font-size: 16px;
							line-height: 22px;
							margin-bottom: 4px;
						}
						.desc{
							color: #fff;
							font-size: 12px;
							line-height: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
					}
                }
            }
        }

        .footer-center {
            width: $width;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            .left {
                // width: 300px;

                .left-phone {
                    font-size: 26px;
                    font-weight: 600;
                    color: $base-color;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $base-color;
                    margin-bottom: 12px;
                }
                .left-email {
                    font-size: 18px;
                    color: #838383;
                }
            }
            .center {
                width: calc(100% - 550px);
                padding: 0 68px;
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 18px;
                    font-weight: 600;
                    color: #838383;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .help-li {
                    font-size: 14px;
                    color: #838383;
                    line-height: 30px;
                    cursor: pointer;
                }

                p:hover, .help-li:hover {
                    color: $base-color;
                }
            }
            .right {
                width: 250px;
                text-align: center;

                .el-image {
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                    text-align: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                p {
                    font-size: 18px;
                    color: #838383;
                }
            }

            .qrcode-hide {
                display: none;
            }
        }

        .footer-bot {
            background: #242424;
			.content{
				padding: 32px 0 46px;
				width: $width;
				margin: 0 auto;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				border-bottom: 1px solid #595959;
			}
			.center {
				flex: 1;
			    display: flex;
				align-items: flex-start;
				justify-content: space-between;
			    .class_name {
			        font-size: 18px;
					line-height: 25px;
			        color: #fff;
			        cursor: pointer;
					margin-bottom: 20px;
			    }
			
			    .help-li {
			        font-size: 14px;
			        color: #DCDCDC;
			        line-height: 20px;
					margin-bottom: 18px;
			        cursor: pointer;
					&:last-child{
						margin-bottom: 0;
					}
			    }
			
			    p:hover, .help-li:hover {
			        color: $base-color;
			    }
			}
			.right {
				.top{
					.tit{
						color: #FFF;
						font-size: 18px;
						line-height: 25px;
						margin-bottom: 16px;
					}
					.phone{
						color: #DCDCDC;
						font-size: 18px;
						line-height: 25px;
						margin-bottom: 17px;
					}
				}
				.scan{
					display: flex;
					flex-direction: column;
					.el-image {
					    width: 102px;
					    height: 102px;
						margin-bottom: 6px;
						img{
							display: block;
							width: 100%;
							height: 100%;
						}
					}
					.scan_text{
						width: 102px;
						text-align: center;
					    font-size: 14px;
						line-height: 20px;
					    color: #DCDCDC;
					}
				}
			    
			}
			
			.qrcode-hide {
			    display: none;
			}
        }
		
		.footer-fot {
			background: #242424;
			color: #9D9D9D;
			display: flex;
			justify-content: space-between;
			.copy_right{
				width: $width;
				margin: 0 auto;
			}
		}
		
        .friendly-link {
            width: $width;
            margin: 0 auto 52px;
			background-color: #fff;
			box-sizing: border-box;

            .link-title {
                line-height: 30px;
                padding: 10px 0 5px;
                margin: 0px 0 15px;
                border-bottom: 1px solid #e8e8e8;
            }
			.items{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				
			}
            .link-item {
                width: 144px;
                height: 60px;
                text-align: center;
                overflow: hidden;
                // margin: 0 6px 10px 6px;
                -webkit-transition: opacity 0.3s, box-shadow 0.3s;
                transition: opacity 0.3s, box-shadow 0.3s;
                border: 1px solid #EAEDF3;
                border-left: none;
                cursor: pointer;
				box-sizing: border-box;
				margin-right: 20px;
				margin-bottom: 20px;
            }
            .clear {
                clear: both;
            }
        }
    }
</style>
<style lang="scss">
    .friendly-link {
        .el-tabs__nav-scroll {
			padding: 20px 20px 18px;
			color: #333;
			font-size: 16px;
			line-height: 22px;
        }
		.el-tabs__item{
			height: auto;
		}
		.el-tabs__item.is-active{
			color: #333;
			font-size: 16px;
			line-height: 22px;
		}
        .el-tabs__content {
			padding: 10px 20px 10px;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
			background-color: #F4F5F7;
        }
        .el-tabs__nav {
            // padding: 0 20px;
        }
        .el-tabs__active-bar {
			display: none;
            // padding: 0 20px;
        }
		.el-tabs__header{
			margin-bottom: 0;
		}
    }
</style>
