//测试
// export default {

// 	// api请求地址
// 	baseUrl: 'http://www.xin-shang-hui.cn/',

// 	// 图片域名
// 	imgDomain: 'http://www.xin-shang-hui.cn/',

// 	// 腾讯地图key
// 	 mpKey: '6ZDBZ-CLSLX-66747-7MVM4-HLK47-XMBXU',

// 	// 客服
// 	 webSocket: 'wss://www.xin-shang-hui.cn/wss',

// 	// api安全
// 	apiSecurity: false,

// 	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
// 	pingInterval: 1500,

// 	// 公钥
// 	publicKey: `{{$publicKey}}`,

// }
//正式
export default {

	// api请求地址
	baseUrl: 'https://www.xin-shang-hui.com/',

	// 图片域名
	imgDomain: 'https://www.xin-shang-hui.com/',

	// 腾讯地图key
	 mpKey: '6ZDBZ-CLSLX-66747-7MVM4-HLK47-XMBXU',

	// 客服
	 webSocket: 'wss://www.xin-shang-hui.com/wss',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: `{{$publicKey}}`,

}
// export default {

// 	// api请求地址
// 	baseUrl: 'http://www.lookmysite.cn/',

// 	// 图片域名
// 	imgDomain: 'http://www.lookmysite.cn/',

// 	// 腾讯地图key
// 	 mpKey: '6ZDBZ-CLSLX-66747-7MVM4-HLK47-XMBXU',

// 	// 客服
// 	 webSocket: 'wss://www.lookmysite.cn/wss',

// 	// api安全
// 	apiSecurity: false,

// 	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
// 	pingInterval: 1500,

// 	// 公钥
// 	publicKey: `{{$publicKey}}`,

// }
