import supplyLayout from "../../layout/supply"

// 登录模块
export default {
    path: "/supply_demand",
    component: supplyLayout,
    redirect: "/index",
    name: "Supply",
    children: [
		{
			path: "/supply_demand",
			name: "supplyDemandCenter",
			meta: {
				module: "supply_demand",
				backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
			},
			component: () => import("@/views/cms/supply_demand/list")
		},
    ]
}
