import MemberLayout from "../../layout/member"

// 会员详情相关路由
const memberDetailRoutes = [{
		path: "index",
		name: "member",
		meta: {
			module: "member",
			auth: true,
		},
		component: () => import("@/views/member/index")
	},
	{
		path: "employee-*",
		name: "employee_detail",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/employee_detail")
	},
	{
		path: "sale_after_statistics",
		name: "sale_after_statistics",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/sale_after_statistics")
	},
	{
		path: "order_payment_statistics",
		name: "order_payment_statistics",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/order_payment_statistics")
	},
	{
		path: "order_purchase_statistics",
		name: "order_purchase_statistics",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/order_purchase_statistics")
	},
	{
		path: "safeguard_rights_statistics",
		name: "safeguard_rights_statistics",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/safeguard_rights_statistics")
	},
	{
		path: "bulk_shipping_count",
		name: "bulk_shipping_count",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/bulk_shipping_count")
	},
	{
		path: "inquiry_statistics",
		name: "inquiry_statistics",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/inquiry_statistics")
	},
	{
		path: "payment_statistics",
		name: "payment_statistics",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/payment_statistics")
	},
	{
		path: "order_statistics",
		name: "order_statistics",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/order_statistics")
	},
	{
		path: "approve",
		name: "approve",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/approve")
	},
	{
		path: "qizongauthentication",
		name: "qizongauthentication",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/qizongauthentication")
	},
	{
		path: "inquiry_overview",
		name: "inquiry_overview",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/member/inquiry_overview")
	},
	{
		path: "terminate_list",
		name: "terminate_list",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/terminate_list")
	},
	{
		path: "invoice_detail",
		name: "invoice_detail",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/invoice_detail")
	},
	{
		path: "invoice_list",
		name: "invoice_list",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/invoice_list")
	},
	{
		path: "delivery_detail",
		name: "delivery_detail",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/delivery_detail")
	},
	{
		path: "delivery_order",
		name: "delivery_order",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/delivery_order")
	},
	{
		path: "payment_detail",
		name: "payment_detail",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/payment_detail")
	},
	{
		path: "payment_list",
		name: "payment_list",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/payment_list")
	},
	{
		path: "contract_list",
		name: "contract_list",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/contract_list")
	},
	{
		path: "contract_examine",
		name: "contract_examine",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/contract_examine")
	},
	{
		path: "order_examine",
		name: "order_examine",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/order_examine")
	},
	{
		path: "bulk_detail",
		name: "bulk_detail",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/order_detail")
	},
	{
		path: "bulk_order",
		name: "bulk_order",
		meta: {
			module: "bulk",
			auth: true
		},
		component: () => import("@/views/bulkOrder/order_list")
	},
	{
		path: "my_inquiry",
		name: "my_inquiry",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/my_inquiry")
	},
	{
		path: "workbench",
		name: "workbench",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/workbench")
	},
	{
		path: "employee_management",
		name: "employee_management",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/employee_management")
	},
	{
		path: "organization_management",
		name: "organization_management",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/organization_management")
	},
	{
		path: "department_management",
		name: "department_management",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/department_management")
	},
	{
		path: "my_supply",
		name: "my_supply",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/my_supply")
	},
	{
		path: "security",
		name: "security",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/security")
	},
	{
		path: "account",
		name: "account",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/account")
	},
	{
		path: "delivery_address",
		name: "delivery_address",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/delivery_address")
	},
	{
		path: "address_edit",
		name: "address_edit",
		meta: {
			module: "member",
			auth: true,
			// parentRouter: "delivery_address" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/member/address_edit")
	},
	{
		path: "collection",
		name: "collection",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/collection")
	},
	{
		path: "my_coupon",
		name: "my_coupon",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/coupon")
	},
	{
		path: "footprint",
		name: "footprint",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/footprint")
	},
	{
		path: "info",
		name: "info",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/info")
	},
	{
		path: "level",
		name: "level",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/level")
	},
	{
		path: "signin",
		name: "signin",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/signin")
	},
	{
		path: "order_list",
		name: "order_list",
		meta: {
			module: "order",
			auth: true
		},
		component: () => import("@/views/order/list")
	},
	{
		path: "my_point",
		name: "my_point",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/my_point")
	},
	{
		path: "activist",
		name: "activist",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/order/activist")
	},
	{
		path: "refund_detail",
		name: "refund_detail",
		meta: {
			module: "order",
			auth: true,
			// parentRouter: "activist" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/refund_detail")
	},
	{
		path: "refund",
		name: "refund",
		meta: {
			module: "order_list",
			auth: true,
			// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/refund")
	},
	{
		path: "complain",
		name: "complain",
		meta: {
			module: "order",
			auth: true,
			parentRouter: "activist" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/complain")
	},
	{
		path: "order_detail",
		name: "order_detail",
		meta: {
			module: "order",
			auth: true,
			// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/detail")
	},
	{
		path: "order_detail_local_delivery",
		name: "order_detail_local_delivery",
		meta: {
			module: "order",
			auth: true,
			// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/detail_local_delivery")
	},
	{
		path: "order_detail_pickup",
		name: "order_detail_pickup",
		meta: {
			module: "order",
			auth: true,
			// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/detail_pickup")
	},
	{
		path: "order_detail_virtual",
		name: "order_detail_virtual",
		meta: {
			module: "order",
			auth: true,
			// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/detail_virtual")
	},
	{
		path: "logistics",
		name: "logistics",
		meta: {
			module: "order",
			auth: true,
			// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/logistics")
	},
	{
		path: "verification",
		name: "verification",
		meta: {
			module: "order",
			auth: true
		},
		component: () => import("@/views/order/verification")
	},
	{
		path: "verification_list",
		name: "verification_list",
		meta: {
			module: "order",
			auth: true
		},
		component: () => import("@/views/order/verification_list")
	},
	{
		path: "verification_detail",
		name: "verification_detail",
		meta: {
			module: "order",
			auth: true,
			parentRouter: "verification_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/order/verification_detail")
	},
	{
		path: "account_list",
		name: "account_list",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("../../views/member/account_list")
	},
	{
		path: "account_edit",
		name: "account_edit",
		meta: {
			module: "member",
			auth: true,
			parentRouter: "account_list" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("../../views/member/account_edit")
	},
	{
		path: "apply_withdrawal",
		name: "apply_withdrawal",
		meta: {
			module: "member",
			auth: true,
			parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("../../views/member/apply_withdrawal")
	},
	{
		path: "withdrawal",
		name: "withdrawal",
		meta: {
			module: "member",
			auth: true
		},
		component: () => import("@/views/member/withdrawal")
	},
	{
		path: "withdrawal_detail",
		name: "withdrawal_detail",
		meta: {
			module: "member",
			auth: true,
			parentRouter: "withdrawal" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/member/withdrawal_detail")
	},
	{
		path: "recharge_list",
		name: "recharge_list",
		meta: {
			module: "member",
			auth: true,
			parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/member/recharge_list")
	},
	{
		path: "recharge_detail",
		name: "recharge_detail",
		meta: {
			module: "member",
			auth: true,
			parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/member/recharge_detail")
	},
	{
		path: "recharge_order",
		name: "recharge_order",
		meta: {
			module: "member",
			auth: true,
			parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/member/recharge_order")
	},
]

// 会员订单相关路由
const memberOrderRoutes = [{
		path: "/evaluate",
		name: "evaluate",
		meta: {
			module: "order",
			auth: true
		},
		component: () => import("@/views/order/evaluate")
	},
	{
		path: "/payment",
		name: "payment",
		meta: {
			module: "order",
			auth: true
		},
		component: () => import("@/views/order/payment")
	},
	{
		path: "/pay",
		name: "pay",
		meta: {
			module: "pay",
			auth: true
		},
		component: () => import("@/views/pay/index")
	},
	{
		path: "/result",
		name: "result",
		meta: {
			module: "pay",
			auth: true
		},
		component: () => import("@/views/pay/result")
	},
	{
		path: "authentication",
		name: "authentication",
		meta: {
			module: "member",
			auth: true,
			parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
		},
		component: () => import("@/views/member/authentication")
	},
]

// 会员模块
export default {
	path: "/member",
	component: MemberLayout,
	redirect: "index",
	alwaysShow: true,
	name: "MemberIndex",
	children: [{
			path: "/member",
			name: "home",
			redirect: "index",
			children: memberDetailRoutes,
			component: () => import("@/views/member/home")
		},
		...memberOrderRoutes
	]
}